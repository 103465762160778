import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
const drawerWidth = 240;
export default function Header(props) {
  let navigate = useNavigate();
  // const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [organization, setOrganization] = useState("");
  const openProfile = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  const username = localStorage.getItem("userName");
  const fullname = localStorage.getItem("fullname");
  const vanity = localStorage.getItem("vanity");
  const picture = localStorage.getItem("picture");
  const organizationValue = localStorage.getItem("organization");
  const location = useLocation();

  const useCurrentCallback = (callback) => {
    const reference = React.useRef();
    reference.current = callback;
    return (...args) => {
      return reference.current?.(...args);
    };
  };

  const [time, setTime] = React.useState(0);
  const currentCallback = useCurrentCallback(() => {
    const date = new Date();
    setTime(date.toISOString());
  });

  async function getOrganization() {
    try {
      var myHeaderss = new Headers();
      myHeaderss.append("username", username);
      var requestOptions = {
        method: "GET",
        headers: myHeaderss,
        redirect: "follow",
      };
      const response = await fetch(
        "https://ecoprismapi.azurewebsites.net/organization-name",
        requestOptions
      );
      if (response.status === 200) {
        const responseJson = await response.json();
        if (responseJson.success == false) {
          setOrganization("");
        } else if (responseJson.status == 400) {
          setOrganization("");
        } else {
          localStorage.setItem("organization", responseJson.data.name);
          setOrganization(responseJson.data.name);
        }
      } else {
        setOrganization("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    const handle = setInterval(currentCallback, 100);
    return () => clearInterval(handle);
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      {location.pathname !== "/login" && (
        <nav className="navbar navbar-expand-lg navbar-light custome__header">
          <div className="container-fluid">
            <img src="assets/img/logoicon.png" alt="" class="img-logo" />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link " aria-current="page">
                    CSRD ecobot
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav mb-2 mb-lg-0 bg-white user-box">
                {/* <li className="nav-item dropdown">
                    <a className="nav-link" href="/home">
                      <img className="icon" src="assets/img/Home.png" alt=""/>
                    </a>
                  </li> 
                  <li className="nav-item dropdown">
                    <a className="nav-link" href="#">
                      <img className="icon" src="assets/img/Notifications.png" alt=""/>
                    </a>
                  </li>  */}
                <li className="nav-item dropdown ">
                  <a
                    className="nav-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    onClick={handleDropdownClick}
                    aria-expanded={isOpen}
                  >
                    <img className="bot-icon ml-7" src={picture} alt="" />
                  </a>
                  {isOpen && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <a className="dropdown-item" href="#" onClick={logout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-item dropdown">
                  <span className="username-text"> {fullname}</span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
      {location.pathname === "/login" && (
        <nav className="navbar navbar-expand-lg custome__header">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img src="assets/img/logo.png" alt="" class="img-logo" />
            </a>
          </div>
        </nav>
      )}
     <div class="mobile-header">
  <div class="row align-items-center">
    <div class="col-4">
      <a class="navbar-brand" href="#">
        <img src="assets/img/logoicon.png" alt="" class="img-logo" />
        <span className="mobile-user">CSRD bot</span>
      </a>
    </div>
    <div class="col-8 text-right">
      <div class="user-info">
        <img class="bot-icon" src={picture} alt="" />
        <span class="username-text">{fullname}</span> 
        <a className="logout-btn" href="#" onClick={logout}>
  <FontAwesomeIcon icon={faSignOutAlt} />
</a>
      </div>
   
    </div>
    
  </div>
</div>


    </React.Fragment>
  );
}
