import logo from "./logo.svg";
import "./App.css";
import { useNavigate } from "react-router-dom";
import React, { Component, useEffect } from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import LinkedInCallback from "./LinkedInCallback";
function AppLandingPage() {
  let navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };

  useEffect(() => {
    // Function to retrieve the LinkedIn authorization code from local storage
    const getLinkedInCode = () => {
      // Get the code data from local storage
      const codeDataJSON = localStorage.getItem("linkedinCode");
      if (codeDataJSON) {
        const codeData = JSON.parse(codeDataJSON);
        // Check if the code is still valid (not expired)
        if (codeData.expirationTime > new Date().getTime()) {
          navigate("/Chatpage");
          return codeData.code; // Return the code if it's still valid
        } else {
          // If the code is expired, remove it from local storage
          localStorage.removeItem("linkedinCode");
          navigate("/");
        }
      }
      return null; // Return null if no valid code is found
    };

    // Call the function to handle redirection based on stored LinkedIn code
    getLinkedInCode();
  }, [navigate]);
  // Function to retrieve the LinkedIn authorization code from local storage
  function getLinkedInCode() {
    // Get the code data from local storage
    const codeDataJSON = localStorage.getItem("linkedinCode");
    if (codeDataJSON) {
      const codeData = JSON.parse(codeDataJSON);
      // Check if the code is still valid (not expired)
      if (codeData.expirationTime > new Date().getTime()) {
        navigate("/Chatpage");
        return codeData.code; // Return the code if it's still valid
      } else {
        // If the code is expired, remove it from local storage
        localStorage.removeItem("linkedinCode");
        navigate("/");
      }
    }
    return null; // Return null if no valid code is found
  }

  // Retrieve the LinkedIn code from local storage
  const storedCode = getLinkedInCode();
  console.log("Stored LinkedIn code:", storedCode);

  const { linkedInLogin } = useLinkedIn({
    clientId: "86gnz36rjrwspk",
    redirectUri: "https://csrdbot.ecoprism.com/callback", // Redirect URI registered in LinkedIn app settings
    scope: "openid profile email",
    onSuccess: (code) => {
      console.log("Login successful. Authorization code:", code);
      // Redirect to Chatpage after successful login
      navigate("/Chatpage");
      // Close the popup window
      window.close();
    },
    onError: (error) => {
      console.error("Error during LinkedIn login:", error);
      getLinkedInCode();
      // Handle error if login fails
    },
  });

  return (
    <div id="wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 dark-bg-ai">
            <div className="green-lg">
              <img
                src="assets/img/green_logo.png"
                alt=""
                className="green-logo"
              />
            </div>
            <div className="aichat-img">
              <img className="landing-img" src="./assets/img/aichat.png"></img>
            </div>
          </div>
          <div className="col-md-4 white-bg-login">
            <div className="login-box">
              <h1 className="ecobot-h1">
                Step into EcoPrism's CSRD AI Navigator
              </h1>
              <p className="ecobot-text">
                Embark on your journey through CSRD and ESRS requirements
              </p>
              <h2 className="ecobot-login-text">Login to Navigate</h2>
              <img
                src="assets/img/linkedin.png"
                alt=""
                onClick={linkedInLogin}
                className="linkedin-btn-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppLandingPage;
