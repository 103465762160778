import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AppLandingPage from "./App";

import Login from "./login";

import ChatPage from "./ChatPage";
import Chat from "./newchat";
import Accessdenied from "./accessdenied";
import Comingsoon from "./comingsoon";
import LinkedInCallback from "./LinkedInCallback";

const router = createBrowserRouter([
    {
      path: "/",
      element: <AppLandingPage />
    },
    {
      path: "/callback",
      element: <LinkedInCallback />
    },
    {
        path: "/login",
        element: <Login />
    },
    
  {
    path: "/accessdenied",
    element: <Accessdenied />
  },
  {
    path: "/comingsoon",
    element: <Comingsoon />
  },
  {
    path: "/newchat",
    element: <Chat />,
  },
   {
    path: "/ChatPage",
    element: <ChatPage />,
  }
  
]);
  
/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  return (
    <RouterProvider router={router} />
  );
}

