import React, { useEffect , useState  } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

function LinkedInCallback() {
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (code) {
      const access = async (code) => {
        let res = await fetch("https://ecoprismapi.azurewebsites.net/linkedin-token", {
          method: "GET",
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'code':code
          }
        });
        
        if (res.status === 200) {
          let resJson = await res.json();
          if (resJson !== null) {
            console.log(resJson);
            const accessToken=resJson.access_token;
            if (accessToken !== null) {
              let res1 =await fetch("https://ecoprismapi.azurewebsites.net/api-link", {
                method: "GET",
                headers: {
                  'token': accessToken,
                }
              });
              if (res1.status === 200) {
                let resJson1 = await res1.json();
                if (resJson1 !== null) {
                  console.log(resJson1);
                  console.log(resJson1.name);
                  console.log(resJson1.picture);
                  const formattedName = resJson1.name.toLowerCase().replace(/\s/g, '');
                  localStorage.setItem('vanity', formattedName);
                  localStorage.setItem('fullname', resJson1.name);
                  localStorage.setItem('picture', resJson1.picture);
                  localStorage.setItem('userName', resJson1.email);
                  const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; 

                  const codeData = {
                    code: code,
                    expirationTime: expirationTime,
                  };
                  localStorage.setItem('linkedinCode', JSON.stringify(codeData));
                  window.close();
                }
              }
              else{

              }
            }
          }     
        } else {
        }
      };
      access(code);
    }
    
    
  }, [location]);

  return null;
}

export default LinkedInCallback;
