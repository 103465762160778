import React, { useState, useRef, useEffect } from "react";
import "./chat.css"; // Import the CSS file
import "./chatPage.css"; // Import the CSS file
import LoginHeader from "./components/LoginHeader.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import dislikesvg from "./assets/img/Dislike.svg";
import copysvg from "./assets/img/Copy.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SendIcon from "@mui/icons-material/Send"; // Import Send icon
import { useNavigate } from "react-router-dom";
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import { Padding } from "@mui/icons-material";
const Chat = () => {
  const [copied, setCopied] = useState(false);
  const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [messages, setMessages] = useState([]);
  const username = localStorage.getItem("userName");
  const vanity = localStorage.getItem("vanity");
  const picture = localStorage.getItem("picture");
  const chatBottom = useRef(null);
  const [citation, setCitation] = useState([]);
  const [typingInfo, setTypingInfo] = useState(null);
  const [chatStarted, setChatStarted] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [relatedContent, setRelatedContent] = useState(null);
  const [relatedHeading, setRelatedHeading] = useState(null);
  const [botTyping, setBotTyping] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

   let navigate = useNavigate();
     useEffect(() => {
     const codeDataJSON = localStorage.getItem("linkedinCode");
    if (codeDataJSON) {
    const codeData = JSON.parse(codeDataJSON);
        // Check if the code is still valid (not expired)
   if (codeData.expirationTime > new Date().getTime()) {
      navigate("/Chatpage");
     return codeData.code; // Return the code if it's still valid
   }
   else
  {
    localStorage.removeItem("linkedinCode");
   navigate("/");
    }
   } else {
    navigate("/");
   }
   }, []);
  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const scrollToBottom = () => {
    if (chatBottom.current) {
      chatBottom.current.scrollTop = chatBottom.current.scrollHeight;
    }
    // if (chatBottom && chatBottom.current) {
    //   chatBottom.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    // }
  };
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      // Resume typing if there is pending typing info
      if (typingInfo) {
        startBotResponseTyping(
          typingInfo.botResponse,
          typingInfo.charIndex,
          typingInfo.startTime
        );
      }
    } else {
      // Save typing info when tab is not visible
      if (
        chatStarted &&
        messages.length > 0 &&
        messages[messages.length - 1].sender === "bot"
      ) {
        setTypingInfo({
          botResponse: messages[messages.length - 1].text,
          charIndex: typingInfo ? typingInfo.charIndex : 0,
          startTime: new Date(),
        });
      }
    }
  };

  const getTime = () => {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}`;
  };

  const startBotResponseTyping = (botResponse, charIndex, startTime) => {
    if (botResponse.text.includes("Generating Answer...")) {
      console.log(botResponse);
      setBotTyping(false);
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory.slice(0, -1),
        botResponse,
      ]);
      // Stop bot typing

      return; // Exit the function
    }
    const typingSpeed = 15; // Adjust the delay as needed for typing speed
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const elapsed = currentTime - startTime;
      const newCharIndex = Math.floor(elapsed / typingSpeed);
      const newText = botResponse.text.substring(0, newCharIndex);
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory.slice(0, -1),
        { text: newText, sender: "bot", time: getTime() },
      ]);
      if (newCharIndex >= botResponse.text.length) {
        clearInterval(intervalId);
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory.slice(0, -1),
          botResponse,
        ]);
        setBotTyping(false);
      }
    }, typingSpeed);
  };

  const sendMessage = async () => {
    if (message.trim() !== "") {
      if (!chatStarted) {
        setChatStarted(true);
      }
      // Store user's message

      const userMessage = { text: message, sender: "user" };

      // Update chat history with user's message
      setChatHistory((prevChatHistory) => [...prevChatHistory, userMessage]);
      setMessage(""); // Clear input field

      try {
        // startBotResponseTyping((prevChatHistory) => [
        //      ...prevChatHistory,
        //     { text: "Generating Answer...", sender: "bot", time: getTime() },
        // ]);
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          { text: "Generating Answer...", sender: "bot", time: getTime() },
        ]);
        setBotTyping(true);
        const response = await fetch(
          "https://ecoprismapi.azurewebsites.net/sendMessage",
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              username: username,
              vanity: vanity,
              text: message,
            },
          }
        );
        if (response.status === 200) {
          const responseJson = await response.json();
          const botResponse = {
            text: responseJson.results.answer,
            sender: "bot",
            citation: responseJson.results.sources, // Include citation information
          };

          //setChatHistory(prevChatHistory => [...prevChatHistory, botResponse]);
          startBotResponseTyping(botResponse, 0, new Date());
        }
      } catch (error) {
        console.error("Error fetching bot response:", error);
      }
    }
  };

  const clearChat = () => {
    setChatHistory([]);
    setChatStarted(false);
    setSidebarOpen(false);
  };
  const handleLike = () => {
    // Handle like action
  };

  const handleDislike = () => {
    // Handle dislike action
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleCopy = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageIndex(index);
        setTimeout(() => {
          setCopiedMessageIndex(null);
        }, 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const handleEdit = (index) => {
    // Handle edit action
    console.log("Edit message:", messages[index]);
  };

  const handleHeadingClick = (heading, content) => {
    if (relatedHeading !== heading) {
      setRelatedHeading(heading);
      setRelatedContent(content);
      setSidebarOpen(true); // Open the sidebar
      setExpanded("panel1"); // Expand the accordion
    } else {
      setSidebarOpen(!sidebarOpen); // Toggle the sidebar if the same heading is clicked again
    }
  };
  const renderTextWithBoldAndLineBreaks = (text) => {
    if (!text) return null;
    // Regular expression to match text between double asterisks
    const boldRegex = /\*\*(.*?)\*\*/g;
    return text.split("\n").map((line, lineIndex) => (
      <div key={lineIndex} className="message-line">
        {line.split(boldRegex).map((part, index) => {
          // Wrap text between double asterisks with <strong> tags
          if (index % 2 === 1) {
            return <strong key={index}>{part}</strong>;
          } else {
            return part;
          }
        })}
      </div>
    ));
  };
  return (
    <div className="ecobot-main">
      <LoginHeader heading="" />
      <div className="main-container">
        <div
          className={`chat-container ${
            sidebarOpen ? "sidebar-open" : "sidebar-close"
          }`}
        >
          <div
            className={`chat-window ${chatStarted ? "" : "chat-not-started"}`}
          >
            <div className="chat-box-msg" ref={chatBottom}>
              {/* Display chat messages */}
              {chatHistory.map((msg, index) => (
                <div key={index} className={`message  ${msg.sender}`}>
                  {msg.sender === "user" && (
                    <>
                      <img
                        src={picture}
                        alt="User Profile"
                        className="profile-icon user-icon"
                      />
                      <span className="csrd-title">You</span>
                      <div className="message-text pd-l-40">
                        {msg.text.split("\n").map((line, index) => (
                          <div key={index}>{line}</div>
                        ))}
                      </div>
                    </>
                  )}
                  {msg.sender === "bot" && (
                    <>
                      <img
                        src="assets/img/boticon.png" // Replace with path to bot icon
                        alt="Bot"
                        className="icon profile-icon bot-icon"
                      />
                      <span className="csrd-title">CSRD ecobot</span>
                      <div className="message-text pd-l-40">
                        {renderTextWithBoldAndLineBreaks(msg.text)}
                        <div className="row">
                          <div className="col-md-8">
                            {msg.citation && (
                              <Accordion
                                expanded={expanded === "panel1"}
                                onChange={() =>
                                  setExpanded(
                                    expanded === "panel1" ? false : "panel1"
                                  )
                                }
                                className="citation-acc"
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2-content"
                                  id="panel2-header"
                                  className="accordion-summ"
                                >
                                  <span className="citation-res">
                                    {msg.citation.length ?? 0} Resources
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-history">
                                  {msg.citation &&
                                    msg.citation.map((citationItem, index) => (
                                      <div
                                        key={index}
                                        className="dummy-heading-container"
                                      >
                                        <div className="pdf-heading-container">
                                          <p
                                            className="dummy-heading"
                                            onClick={() =>
                                              handleHeadingClick(
                                                citationItem.document_id,
                                                citationItem.content
                                              )
                                            }
                                          >
                                            {citationItem.document_id}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                      </div>
                      <div className="row mt20 mb20">
                        <div className="col-md-6">
                          <div className="action-icons pd-l-40">
                            {/* <img
                              src={dislikesvg}
                              alt="Dislike"
                              className="action-icon"
                              onClick={handleDislike}
                            /> */}
                            <img
                              src={copysvg}
                              alt="Copy"
                              className="action-icon"
                              onClick={() => handleCopy(msg.text, index)} // Pass the index of the message
                            />
                            {copiedMessageIndex === index && (
                              <span className="copied-message">Copied</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 text-right pd-r50">
                          <span className="font-13">
                            AI-generated content may be incorrect
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
              {/* <div ref={chatBottom}></div> */}
              {!chatStarted && (
                <div className="chat-image">
                  <img
                    src="assets/img/chatstart.png"
                    alt="Chat Image"
                    className="chat-start"
                  />
                </div>
              )}
            </div>
            <div className="input-container">
              <input
                type="text"
                value={message}
                onChange={handleMessageChange}
                onKeyPress={handleKeyPress}
                placeholder="Search Anything"
                className="input-box"
                disabled={botTyping}
              />
              <button
                onClick={sendMessage}
                disabled={botTyping}
                className="send-button"
              >
                <SendIcon />
              </button>
              {/* Replace Clear Chat button with ClearIcon */}
              <button disabled={botTyping} style={{ padding: "0px" }}>
                <FontAwesomeIcon
                  icon={faBroom}
                  onClick={clearChat}
                  className="clear-chat-icon"
                />
              </button>
            </div>
          </div>

          <div className="chat-sidebar">
            {/* Content for the sidebar */}
            <div className="sidebar-content">
              <button
                className="close-sidebar-button"
                onClick={handleCloseSidebar}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <h3 className="citation-text">{relatedHeading}</h3>
              {relatedContent &&
                relatedContent
                  .split("\n")
                  .map((line, index) => <div key={index}>{line}</div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
