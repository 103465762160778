import React from "react";
import { useNavigate } from 'react-router-dom';
import Link from "@mui/material/Link";
import LoginHeader from "./components/LoginHeader.js";
import { authProvider } from './authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { useEffect, useState } from "react";
import Swal from 'sweetalert2';

export default function Home() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [organization, setOrganization] = useState("");
  const [companyid, setCompanyId] = useState("");

  async function getOrganization() {
    try {
      var myHeaderss = new Headers();
      const username = localStorage.getItem('userName');
      myHeaderss.append("username", username);
      var requestOptions = {
        method: 'GET',
        headers: myHeaderss,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/organization-name',requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if(responseJson.success == false){
          setOrganization("");
        }
        else if(responseJson.status == 400){
          setOrganization("");
        }
        else{
          localStorage.setItem('organization', responseJson.data.name);
          setOrganization(responseJson.data.name);
         
        }
      }
      else{
        setOrganization("");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getCompanyId() {
    try {
      var myHeaderss = new Headers();
      const username = localStorage.getItem('userName');
      myHeaderss.append("username", username);
      var requestOptions = {
        method: 'GET',
        headers: myHeaderss,
        redirect: 'follow'
      };
      const response = await fetch('https://ecoprismapi.azurewebsites.net/getcompanyid',requestOptions);
      if (response.status === 200) {
        const responseJson = await response.json();
        if(responseJson.success == false){
          setCompanyId("");
        }
        else if(responseJson.status == 400){
          setCompanyId("");
        }
        else{
          localStorage.setItem('companyid', responseJson.data);
          setCompanyId(responseJson.data);
        }
      }
      else{
        setCompanyId("");
      }
    } catch (error) {
      console.error(error);
    }
  }


  const handleLogin = () => {
    console.log('in')
    if(email === "demo@ecoprism.com" ) {
      localStorage.setItem('userName', "demo@ecoprism.com");
      localStorage.setItem('username', "demo@ecoprism.com"); 
      getOrganization(); 
      getCompanyId();
      navigate('/home')
    }
    else if(email === "danishagro@test.com" ) {
      localStorage.setItem('userName', "danishagro@test.com"); 
      localStorage.setItem('username', "danishagro@test.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    } 
    else if(email === "admin@ecoprism.com" ) {
      localStorage.setItem('userName', "admin@ecoprism.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    } 
    else if(email === "ecodev@acumant.com" ) {
      localStorage.setItem('userName', "ecodev@acumant.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    }
    
    else if(email === "ecodemo@ecoprism.com" ) {
      localStorage.setItem('userName', "ecodemo@ecoprism.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    }
   
    else if(email === "ecopowerbi@ecoprism.com" ) {
      localStorage.setItem('userName', "ecopowerbi@ecoprism.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    }
    else if(email === "testdev@ecoprism.com" ) {
      localStorage.setItem('userName', "testdev@ecoprism.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    }
    else if(email === "danishagro@ecoprism.com" && password === "Ecoprism@2023") {
      localStorage.setItem('userName', "danishagro@ecoprism.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    }
    else if(email === "abbglobal@ecoprism.com" && password === "abbglobal@2023") {
      localStorage.setItem('userName', "abbglobal@ecoprism.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    }
    else if(email === "abb_global@ecoprism.com" && password === "abb@2023") {
      localStorage.setItem('userName', "abb_global@ecoprism.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    }
    else if(email === "automtest@ecorism.com" && password === "automtest@2023") {
      localStorage.setItem('userName', "automtest@ecorism.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    }
    else if(email === "demoeco@ecoprism.com" && password === "Ecoprism@2023") {
      localStorage.setItem('userName', "demoeco@ecoprism.com");  
      getOrganization();
      getCompanyId();
      navigate('/home')
    }
    else {
        setError('Email/Password is incorrect')
    }
  }

  function deleteCookies() {
    var Cookies = document.cookie.split(';');

    // set 1 Jan, 1970 expiry for every cookies
    for (var i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handlePass = (e) => {
    setPassword(e.target.value)
  }
  return (
    <div id="wrapper">
     <div className="login__wrapper">
      <LoginHeader heading=""/>
        <section className="login__section">
          <div className="row">
            <div className="col-lg-12">
              <div className="login__inner">
                <div className="login__left">
                  <img className="login__left-img" src="./assets/img/login.png" alt=""/>
                </div>
                <div className="login__right">
                  <h2 className="login__title">Sign in</h2>
                  <form>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">Email address</label>
                      <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} onChange={handleEmail}/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">Password</label>
                      <input type="password" className="form-control" id="exampleInputPassword1" value={password} onChange={handlePass}/>
                    </div>
                    <div class="mb-3 form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                      <label className="form-check-label" for="exampleCheck1">Check me out</label>
                    </div>
                    <button type="submit" className="btn btn-primary signin__btn" onClick={handleLogin}>Sign in</button>
                    {error && <div id="emailHelp" className="form-text">{error}</div>}
                  </form>
                    <Link to="/" className="forgot__text">Forgot Password</Link>
                    <div className="or__text">OR</div>

                    <AzureAD provider={authProvider}>
                      {
                          ({login, logout, authenticationState, error, accountInfo}) => {
                            console.log(authenticationState);
                            const allowedTenants = ['82c61e8c-e5bf-4c20-935b-686cd5fcf8fb','2c4f8d59-3090-4fc7-a1d7-19a73ee1811c'];
                            //const allowedTenants = ['2c4f8d59-3090-4fc7-a1d7-19a73ee1811c'];
                            switch (authenticationState) {
                              case AuthenticationState.Authenticated:
                                const user=accountInfo.account.userName;
                                const tenantid= accountInfo.account.idToken.tid;
                                console.log(accountInfo);
                                console.log(tenantid);
                                if (allowedTenants.includes(tenantid)) {
                                  let rest = fetch(
                                    "https://ecoprismapi.azurewebsites.net/check-user",
                                    {
                                      method: "GET",
                                      headers: {
                                        'Content-type': 'application/json; charset=UTF-8',
                                        'username': user
                                      },
                                    }
                                  );
                              
                                  rest.then(response => {
                                    if (response.status === 200) {
                                      response.json().then(responseJson1 => {
                                        if (responseJson1.data === true) {
                                          console.log(responseJson1);
                                          localStorage.setItem('userName', accountInfo.account.userName);
                                          return (
                                            navigate("/dashboard")
                                          );
                                        } 
                                        else{
                                          deleteCookies();
                                          localStorage.clear();
                                          return (
                                            navigate("/accessdenied")
                                          );
                                        }
                                      });
                                    }
                                  }).catch(error => {
                                    console.error("An error occurred:", error);
                                  });
                                } else {
                                  return (
                                    navigate("/accessdenied")
                                  );
                                }
                              case AuthenticationState.Unauthenticated:
                                return (
                                    <button className="btn google__btn" onClick={login}>
                                      <img src="assets/img/microsoft_icon.png" alt=""/>
                                        Sign in with Microsoft
                                    </button>
                                );
                              case AuthenticationState.InProgress:
                                return (
                                    <button className="btn google__btn" onClick={login}>
                                      <img src="assets/img/microsoft_icon.png" alt=""/>
                                      Sign in with Microsoft
                                    </button>
                                );
                            }
                          }
                      }
                    </AzureAD>
                    
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  
  );
}
function App() {
  return (
    <div className="App">
      <AzureAD provider={authProvider}>
        <span>Only authenticated users can see me.</span>
      </AzureAD>
      
    </div>
  );
}
